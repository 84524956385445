<template>
  <div class="language">
    <div class="market_top">
      <div class="left">
        <div class="pic">
          <img src="../../assets/img/home_tatol_icon.png" alt />
        </div>
        <p class="title">BTCBAY</p>
      </div>
      <div @click="goback" class="system-closeb">
        <img src="../../assets/img/system-closeb.png" alt />
      </div>
    </div>
    <div class="language-item">
      <div
        @click="languageChange(item)"
        v-for="(item, index) in navList"
        :key="index"
        class="item"
        :class="switchList == item.switchList ? 'select' : ''"
      >
        <!-- <span class="iconfont icon-sousuo"></span> -->
        <div v-if="switchList == item.switchList">
          <img  :src="item.icon2" alt />
        </div>
        <div v-else>
          <img  :src="item.icon" alt />
        </div>

        <p>{{ item.label }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      switchList: "",

      img: [
        require("../../assets/img/icon1.png"),
        require("../../assets/img/icon2.png"),
        require("../../assets/img/icon3.png")
      ],
      img2: [
        require("../../assets/img/icon1_2.png"),
        require("../../assets/img/icon2_2.png"),
        require("../../assets/img/icon3_2.png")
      ]
    };
  },
  created() {
    if (
      navigator.language === "zh-CN" &&
      !localStorage.getItem("currentNation")
    ) {
      this.currentNation = "简体中文";
      this.$i18n.locale = "ZH";
      localStorage.setItem("currentNation", "ZH");
    } else if (
      navigator.language === "en" &&
      !localStorage.getItem("currentNation")
    ) {
      this.currentNation = "English";
      this.$i18n.locale = "EN";
      localStorage.setItem("currentNation", "EN");
    } else if (
      navigator.language === "ja" &&
      !localStorage.getItem("currentNation")
    ) {
      this.currentNation = "日本語";
      this.$i18n.locale = "JA";
      localStorage.setItem("currentNation", "JA");
    } else if (
      navigator.language === "ko" &&
      !localStorage.getItem("currentNation")
    ) {
      this.currentNation = "한국어";
      this.$i18n.locale = "KO";
      localStorage.setItem("currentNation", "KO");
    }
  },
  mounted() {
    this.switchList = localStorage.getItem("typenav");
  },
  computed: {
    navList: function() {
      return [
        {
          label: this.$t("行情热搜"),
          index: 0,
          switchList: "market",
          icon: require("../../assets/img/icon1.png"),
          icon2: require("../../assets/img/icon1_2.png"),
        },
        {
          label: this.$t("交易平台"),
          index: 1,
          switchList: "tradingPlatform",
          icon: require("../../assets/img/icon2.png"),
          icon2: require("../../assets/img/icon2_2.png"),
        },
        {
          label: this.$t("资讯公告"),
          index: 3,
          switchList: "information",
          icon: require("../../assets/img/icon3.png"),
          icon2: require("../../assets/img/icon3_2.png"),
        }
      ];
    }
  },
  methods: {
    goback() {
      this.$router.push("/market");
    },
    languageChange(item) {
      localStorage.setItem("typenav", item.switchList);
      if(item.switchList == 'market') {
        this.$router.push("/market");
      } else if(item.switchList == 'tradingPlatform') {
        this.$router.push("/exchange");
      } else if(item.switchList == 'information') {
        this.$router.push("/information");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.language {
  .market_top {
    border-bottom: 1px solid rgba(153, 153, 153, 0.2);
    padding: 0 28px 0 32px;
    // width: 100%;
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .pic {
        // padding-right: 20px;
        img {
          width: 56px;
          height: 56px;
          margin-right: 20px;
        }
        p {
          font-size: 38px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          color: #333333;
          line-height: 53px;
        }
      }
    }

    .system-closeb {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .language-item {
    .item {
      display: flex;
      align-items: center;
      height: 89px;
      font-size: 28px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #999999;
      line-height: 89px;
      border-bottom: 1px solid rgba(153, 153, 153, 0.2);
      div {
        height: 100%;
        display: flex;
        align-items: center;
        img {
          margin: 0 26px;
          width: 42px;
        }
      }
    }

    .select {
      color: #007aff;
    }
  }
}
</style>
